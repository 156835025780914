import React, { useState } from 'react'
import styled from 'styled-components'
import arrow from '../../../images/down-arrow.svg'
import q from '../../../images/question.svg'
import a from '../../../images/answ.svg'
import theme from '../../styling/theme'

interface QuestionProps {
  children: React.ReactNode
  question: string
  last?: boolean
  first?: boolean
  isOpen?: boolean
  toggleAnswer(): void
}
interface ContainerProps {
  last?: boolean
  first?: boolean
}

const FaqQuestion = ({
  question,
  isOpen,
  children,
  last,
  first,
  toggleAnswer,
}: QuestionProps) => {
  return (
    <Container last={last} first={first}>
      <Question isOpen={isOpen} onClick={toggleAnswer}>
        <p>
          <img src={q} alt="q" />
          {question}
        </p>{' '}
        <FaqButton isOpen={isOpen} src={arrow} />
      </Question>
      <AnswerContainer isOpen={isOpen}>
        <Answer isOpen={isOpen}>{children}</Answer>
      </AnswerContainer>
    </Container>
  )
}

interface FaqButtonProps {
  isOpen?: boolean
}

const FaqButton = styled.img<FaqButtonProps>`
  width: 18px;
  transition: all 0.2s ease;
  ${props => props.isOpen && `transform: rotate(180deg)`};
`

const AnswerContainer = styled.div<IAnswerProps>`
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-out;
  transition-delay: 0;

  ${props => props.isOpen && `max-height: 500px`}
`

const Container = styled.div<ContainerProps>`
  border-bottom: 1px solid #eee;
  width: 100%;
  padding: 30px 0;

  border-top: ${props => props.first && '1px solid #eee'};
`

const Question = styled.div`
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  text-decoration: none;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  cursor: pointer;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  & p {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    @media (max-width: 600px) {
      align-items: flex-start;
    }

    @media (min-width: 1024px) {
      font-size: 18px;
      line-height: 26px;
    }

    & img {
      width: 23px;
      margin-right: 10px;
    }
  }

  @media (max-width: 1023px) {
    padding: 0 20px;

    & p {
      width: 80%;
    }
  }
`

interface IAnswerProps {
  isOpen: boolean
}

const Answer = styled.div<IAnswerProps>`
  padding: 42px 0;

  @media only screen and (max-width: 1023px) {
    padding: 20px 0 0;
  }

  & p {
    margin-bottom: 0px;
    padding: 0 53px;
    color: ${theme.text.second};
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    @media (min-width: 1023px) {
      font-size: 18px;
      line-height: 28px;
      padding: 0 33px;
    }
  }

  @media (max-width: 1023px) {
    ${props =>
      props.isOpen &&
      `
      padding: 24px 0;
      `}
  }
`

export default FaqQuestion
