import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import GeneralHeader from '../components/Header/GeneralHeader'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import Faq from '../components/shared/FAQ/Faq'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import ClaimsProcessAnimation from '../components/ClaimsProcessAnimation/ClaimsProcessAnimation'

export const qs: Array<IQuestions> = [
  {
    question:
      'Can I claim R&D tax credits even if I haven’t paid Corporation Tax?',
    answer: (
      <p>Yes. Even loss-making companies are eligible for R&D tax relief.</p>
    ),
  },
  {
    question: 'What kind of businesses can claim R&D Tax Credits?',
    answer: (
      <p>
        All UK-registered limited companies are eligible – it really doesn’t
        matter what industry sector they operate in. A limited-liability
        partnership (LLP) can only claim if a Corporation Tax-paying firm
        outsourced some of its R&D to the LLP. Sole traders are ineligible.
      </p>
    ),
  },
  {
    question: 'What kind of expenditure qualifies?',
    answer: (
      <p>
        There is a lot that can qualify for R&D tax relief. This can range from
        planning, designing, testing and analysis methods through to the
        creation and adaptation of materials, equipment or software. Even staff
        time given to things like the record-keeping, maintenance, security
        protection and training in relation to the R&D project can all count
        under the R&D Tax Credit Scheme. But it’s worth getting in touch to find
        out more.
      </p>
    ),
  },
  {
    question: 'Why would the government do this?',
    answer: (
      <p>
        Throughout history, the UK – birthplace of the telescope, the telephone,
        the television and the text message – has been at the forefront of
        innovation and invention. To keep the country at the cutting edge, the
        government’s Industrial Strategy has committed to raising total R&D
        expenditure to 2.4% of GDP by 2027, up from 1.7% in 2018. And, because
        government research shows that every pound it gives out in R&D tax
        relief returns between £2.40 and £2.70 in extra R&D investment, the R&D
        Tax Credits scheme has been designed to facilitate this.
      </p>
    ),
  },
  {
    question: 'What could my claim be worth?',
    answer: (
      <p>
        That depends on your company’s circumstances. But when it released its
        figures for 2018-19 – the year for which the figures are most recently
        available – the government found the UK’s biggest firms had made 7,105
        claims under the RDEC scheme, for a total of £2.4 billion. That made the
        average large-company RDEC claim almost £350,000. There were 52,160
        claims under the SME scheme with the average pay-out standing at
        £57,515. A further £265 million was shared by small and medium sized
        companies from the RDEC scheme.
      </p>
    ),
  },
  {
    question: 'How is it paid?',
    answer: (
      <p>
        Some claims are purely for a reduction in Corporation Tax liability. But
        in some cases, an SME might use its R&D tax credit to reduce its tax
        bill to zero, and then take the rest as a cash payment. In other cases,
        the SME might carry forward its tax credit to future years. A
        large-company, RDEC claim will normally be paid back as a cash rebate on
        the paid tax.
      </p>
    ),
  },
]

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Claims Process',
    url: '/claims-process',
  },
]
const TaxCredit = () => {
  return (
    <Layout
      activeLink={'/claims-process'}
      title="R&D Our claims process"
      description="R&D Our claims process"
    >
      <GeneralHeader
        breadcrumbData={breadcrumbData}
        heading="Our claims process"
      />

      {/* <PostList posts={posts} title="Latest posts" /> */}
      {/* <Pagination pageContext={pageContext} pathPrefix="/" /> */}
      <ClaimsProcessAnimation
        tag="HOW TO CLAIM"
        heading="Steps to our tailored process for R&D tax credit claims"
      />
      <PromotionBanner />
      <Faq questions={qs} />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default TaxCredit
