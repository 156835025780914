import React, { useState } from 'react'
import styled from 'styled-components'
import FaqQuestion from './FaqQuestion'
import qa from '../../../images/tax-credit/qa.svg'
import theme from '../../styling/theme'
import MainGrid from '../../styling/MainGrid'

interface IQuestions {
  question: string
  answer: React.ReactNode
}

interface FaqProps {
  questions: Array<IQuestions>
}

const Faq = ({ questions }: FaqProps) => {
  const [activeQuestion, toggleQuestion] = useState(null)

  const setActiveQuestion = i => {
    if (activeQuestion === i) {
      toggleQuestion(null)
    } else {
      toggleQuestion(i)
    }
  }
  return (
    <Container>
      <MainGrid noPaddingMobile>
        <InnerContainer>
          <HeadingContainer>
            <FaqIcon src={qa} />
            <Heading>Frequently asked questions</Heading>
          </HeadingContainer>
          {questions.map((question, i) => {
            return (
              <FaqQuestion
                question={question.question}
                key={i}
                last={i >= questions.length - 1}
                isOpen={i === activeQuestion}
                first={i === 0}
                toggleAnswer={() => setActiveQuestion(i)}
              >
                {question.answer}
              </FaqQuestion>
            )
          })}
        </InnerContainer>
      </MainGrid>
    </Container>
  )
}

export default Faq

const InnerContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 20;
  padding: 50px 0 0px;

  background: ${theme.background.hpHeader};

  @media (min-width: 1024px) {
    padding: 10rem 0;
  }
`
const HeadingContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FaqIcon = styled.img`
  width: 120px;
  margin-bottom: 15px;
`

const Heading = styled.h1`
  margin-bottom: 50px;
  font-size: 36px;
  line-height: 36px;
  font-weight: 300;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 30px;
  }

  @media (max-width: 850px) {
    font-size: 16px;

    line-height: 19px;
  }
`
