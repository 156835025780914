import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SectionHeading from '../shared/SectionHeading'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import { throttled } from '../../util/functions'
import img1 from '../../images/claims/claims1.svg'
import img2 from '../../images/claims/claims2.svg'
import img3 from '../../images/claims/claims3.svg'
import img4 from '../../images/claims/claims4.svg'
import img5 from '../../images/claims/claims5.svg'
import dots from '../../images/homepage/dots-green.svg'

interface IClaimsProcessAnimation {
  tag?: string
  heading?: string
}
const ClaimsProcessAnimation = ({ tag, heading }: IClaimsProcessAnimation) => {
  const [greenHeight, setGreenHeight] = useState(0)

  useEffect(() => {
    const calcPercent = (): void => {
      if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const container: HTMLElement | null = document.getElementById(
          'claims-animation'
        )

        if (container) {
          let offsetValue: number = 0
          let top: number = 0
          if (window.innerWidth <= 767) {
            offsetValue = 400
          } else {
            offsetValue = 400
          }
          top = container.getBoundingClientRect().top - offsetValue
          const height: number = container.getBoundingClientRect().height

          if (top > 0) {
            setGreenHeight(0)
          } else if (top < -height) {
            setGreenHeight(170)
          } else {
            setGreenHeight(Math.round((Math.abs(top) / height) * 100))
          }
        }
      }
    }

    const eventListener = throttled(100, calcPercent)

    window.addEventListener('scroll', eventListener)

    return () => {
      window.removeEventListener('scroll', eventListener)
    }
  }, [])
  return (
    <Container>
      <MainGrid>
        <HeadingContainer>
          {tag && <p className="section-tag">{tag}</p>}

          {heading && <SectionHeading center>{heading}</SectionHeading>}
        </HeadingContainer>
        <AnimationContainer id="claims-animation">
          <GreyLine>
            <Green height={greenHeight}>
              <Dots src={dots} alt="Three green dots" />
            </Green>
          </GreyLine>

          <Step>
            <Img src={img1} alt="Eligibility evaluation" marginright />
            <Text marginleft>
              <p className="section-tag">STEP 1</p>
              <h4>Eligibility evaluation</h4>
              <Body>
                A free, no-obligation, exploratory conversation with our
                specialist team to determine whether or not your company
                qualifies. This is easily established either in person, on the
                phone or via email. If you’re a limited company and have spent
                time and money engaged in R&D activities, then it’s highly
                likely that you have a claim.
              </Body>
            </Text>
          </Step>

          <Step reverse>
            <Img src={img2} alt="Eligibility evaluation" marginleft />
            <Text marginright>
              <p className="section-tag">STEP 2</p>
              <h4>Getting to know you and your business</h4>
              <Body>
                We will then arrange to come and visit you, not just to get the
                lowdown on your business, but to introduce ourselves and
                demonstrate our commitment to your company. We pride ourselves
                on our personalised service and we don’t believe it can be
                delivered via a few telephone calls.
              </Body>
            </Text>
          </Step>

          <Step>
            <Img src={img3} alt="Eligibility evaluation" marginright />
            <Text marginleft>
              <p className="section-tag">STEP 3</p>
              <h4>Data gathering meeting</h4>
              <Body>
                Prior to the meeting we will provide you with a couple of
                fillable PDF’s. One asks for basic information about your
                company, staff and accounts and the other asks you to provide
                details of where you believe you have undertaken R&D. These
                forms can be modified by you and us, both during the visit and
                after we leave, to ensure all the projects we have identified as
                eligible are correct.
              </Body>
            </Text>
          </Step>

          <Step reverse>
            <Img src={img4} alt="Eligibility evaluation" marginleft />
            <Text marginright>
              <p className="section-tag">STEP 4</p>
              <h4>Comprehensive reporting</h4>
              <Body>
                After reviewing the data we will send you a draft of the report,
                which details the projects we have selected for submission to
                HMRC and a calculation of your total R&D tax benefit. Once this
                is signed off by you we submit the report on your behalf and
                conduct all future correspondence with HMRC. If there are any
                inquiries opened by HMRC we will resolve these for you at no
                extra cost.
              </Body>
            </Text>
          </Step>

          <Step>
            <Img src={img5} alt="Eligibility evaluation" marginright />
            <Text marginleft>
              <p className="section-tag">STEP 5</p>
              <h4>Collect your payment</h4>
              <Body>
                A typical claim takes between four and six weeks to complete and
                the credit is paid directly into the nominated bank account. If your
                business is profitable your overall Corporation Tax bill will be
                reduced, or if you have already paid your bill for the period,
                you will receive a repayment. Alternatively, if you made a loss
                for the period, you will receive a cash credit.
              </Body>
            </Text>
          </Step>
        </AnimationContainer>
      </MainGrid>
    </Container>
  )
}

export default ClaimsProcessAnimation

const Container = styled.div`
  padding-top: 40px;
  @media (min-width: 768px) {
    padding-top: 8.5rem;
  }
`

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 76rem;
  margin: 0 auto;
`
const AnimationContainer = styled.div`
  margin-top: 73px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media (min-width: 1024px) {
    margin-top: 6.8rem;
  }
`

const GreyLine = styled.div`
  position: absolute;
  top: 0px;
  left: 7px;
  width: 3px;
  height: 100%;
  background-color: #eeeeee;
  z-index: 2;

  @media (min-width: 768px) {
    left: 50%;
  }
`

interface IGreen {
  height: number
}
const Green = styled.div<IGreen>`
  position: absolute;
  top: -70px;
  left: 0px;
  width: 3px;
  height: ${props => props.height + '%'};
  background-color: ${theme.brand.blue};
  z-index: 2;
  transition: all 0.2s ease;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @media (min-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
  }
`

const Dots = styled.img`
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
`

interface IStep {
  reverse?: boolean
}

const Step = styled.div<IStep>`
  display: flex;
  flex-direction: column;
  padding-bottom: 64px;
  margin-left: 35px;
  @media (min-width: 768px) {
    padding: 6.4rem 0;
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
    margin-left: 0px;
  }
`
const Img = styled.img`
  width: 100%;

  @media (min-width: 768px) {
    width: 48%;
    margin-right: ${props => props.marginright && '6rem'};
    margin-left: ${props => props.marginleft && '6rem'};
  }
`

const Text = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 48%;
    margin-right: ${props => props.marginright && '6rem'};
    margin-left: ${props => props.marginleft && '6rem'};
  }

  & .section-tag {
    margin-bottom: 10px;
    @media (min-width: 768px) {
      margin-bottom: 25px;
    }
  }

  & h4 {
    font-weight: bold;
    margin-bottom: 10px;
  }
`

const Body = styled.p`
  margin-bottom: 10px;
  @media (min-width: 768px) {
    margin-bottom: 5px;
  }
`
